.find-colleague {
	&-header {
		margin-left: $spacing-m;
		margin-bottom: 5px;
		text-transform: uppercase;
	}

	&-intro-card {
		background-color: $color-main-80;
		border-radius: $default-border-radius;
		min-height: 150px;
		margin: $default-spacing;
		padding: $spacing-m 0;
		box-shadow: 4px 4px 20px rgb(0 0 0 / 10%);
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: column;

		.info-text {
			font-weight: $font-weight-bold;
			color: $color-main-0;
			text-align: center;
			margin: 0 $spacing-m;
			margin-bottom: $spacing-s;
			font-size: $font-size-lg;
		}

		.additional-info {
			font-size: $font-size-lg;
			text-align: center;
			margin: 0 $spacing-m;
			margin-bottom: $spacing-s;
		}

		.placeholder-photo,
		.mini-profile-photo {
			background-color: $color-neutral-light;
			width: 32px;
			height: 32px;
			display: flex;
			justify-content: center;
			border-radius: 50%;
			align-items: center;
			margin-left: -10px;
			margin-bottom: $spacing-s;

			img {
				width: 30px;
				height: 30px;
				border-radius: 50%;
			}
		}

		.button-redirect {
			background-color: $color-main-0;
			border-color: $color-main-0;

			.icon {
				width: 15px;
				height: 15px;
				margin-right: 5px;
			}
		}
	}
	&-photos {
		display: flex;
		align-items: center;
		position: relative;
		justify-content: center;
		margin-left: 10px;

		.number-circle,
		.ph-item,
		.ph-row,
		.ph-col-12 {
			width: 30px;
			height: 30px;
			border-radius: 50%;
			margin: 0;
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.number-circle {
			background-color: $color-neutral-60;
			color: $color-neutral-light;
			border: 2px solid $color-neutral-light;
			margin-left: -10px;
			margin-bottom: $spacing-s;
		}
	}
}
