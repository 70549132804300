.stats-and-badges {
	&-loader {
		display: flex;
		flex-grow: 1;
		justify-content: center;
		align-items: center;
	}

	&-container {
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: auto;

		.separator {
			flex-shrink: 1 0 auto;
			height: 10px !important;
			width: 100%;
			background-color: rgba(187, 187, 187, 0.18);
		}
		.leadership-board {
			&-wrapper {
				padding: $spacing-m;
			}
			&-card {
				padding: $spacing-m;
				border-radius: 12px;
				background-color: $color-blue-10;
				display: flex;
				justify-content: space-between;
				align-items: center;

				p {
					margin: 0;
				}
				.card-title {
					font-size: $font-size-mmlg;
					font-weight: $font-weight-bold;
					margin-bottom: 6px;
				}
				.card-info {
					font-size: $font-size-lg;
					font-weight: $font-weight-normal;
				}

				.icon-wrapper {
					background-color: $color-main-0;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 10px;
					width: 40px;
					height: 40px;
				}
			}
		}
	}

	&-stats {
		box-sizing: border-box;
		padding: $padding-s;
		padding-top: $padding-m;
		display: flex;
		flex-direction: column;

		.personal-statistics {
			font-size: $font-size-mlg;
			font-weight: 700;
			display: flex;
			justify-content: space-between;
			color: $color-main;

			.see-all {
				font-size: 14px;
				cursor: pointer;
				display: flex;
				flex-direction: row;
				justify-items: center;
				align-items: center;

				.icon {
					width: 12px;
					color: $color-main;
				}
			}
		}

		.stats-preview {
			&-container {
				margin-top: 24px;
			}

			&-content {
				display: flex;
				flex-direction: column;
				gap: 10px;
				margin-top: 10px;

				.first-line {
					display: flex;
					flex-direction: row;
					gap: 10px;

					.first-element {
						width: 100%;
					}

					.second-element {
						width: 100%;
					}
				}

				.second-line {
					display: flex;
				}
			}
		}

		.bold {
			font-weight: bold;
		}
	}

	&-badge {
		box-sizing: border-box;
		padding: $padding-s;
		padding-top: $padding-m;
		display: flex;
		flex-direction: column;

		.badges {
			font-size: $font-size-mlg;
			font-weight: $font-weight-bold;
			display: flex;
			justify-content: space-between;
			color: $color-main;

			.see-all {
				font-size: $font-size-md;
				cursor: pointer;
				display: flex;
				flex-direction: row;
				justify-items: center;
				align-items: center;

				.icon {
					width: 12px;
					color: $color-main;
				}
			}
		}
	}
}

.stats-count {
	&-container {
		padding: 18px $padding-sm;
		box-shadow: 0px -4px 20px rgb(0 0 0 / 10%);
		border-radius: 10px;
		background-color: #fff;
		min-height: 120px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border: 1px solid $color-main-90;
	}

	&-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&-count {
		font-size: $font-size-xxlg;
		color: $color-main;
		font-weight: $font-weight-bold;
		padding: 5px 0;
	}

	&-title {
		padding: 0 20%;
		font-weight: $font-weight-medium;
		font-size: $font-size-lg;
		margin-bottom: 10px;
		text-align: center;
	}
}

.stats {
	&-container {
		margin-top: $spacing-s;
		overflow-y: auto;

		&-range {
			margin-top: $spacing-lg;
			padding: 0 $spacing-m;
		}

		&-grid {
			display: flex;
			flex-direction: column;
			gap: 10px;
			padding-top: $padding-sm;
			padding: $spacing-m $spacing-m 0 $spacing-m;

			&-split {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 10px;
			}
		}

		.last-updated {
			padding: 0 $spacing-m;
		}
	}
}

.badges-main {
	overflow: auto;
}

.badges {
	&-container {
		margin: 0 $spacing-m;
		margin-top: $spacing-lg;
		display: flex;
		flex-direction: column;
		gap: $spacing-lg;

		&-section {
			padding-bottom: $padding-s;

			&-title {
				font-size: $font-size-mlg;
				color: $color-main;
				font-weight: $font-weight-medium;

				&-icon {
					width: 20px;
					height: 20px;
					color: $color-main;
					margin-right: 6px;
				}
			}
		}
	}
}

.badge-preview-container {
	display: flex;
	gap: $spacing-xs;
	padding-top: $padding-m;
	padding-right: $padding-s;
	padding-bottom: $padding-s;
	border-bottom: 1px solid $color-neutral-95;

	&-icon img {
		width: 84px;
		height: 84px;
	}
	&-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-grow: 1;
	}

	.completed {
		color: $color-blue-40;
	}

	&-title {
		font-size: $font-size-mmlg;
		font-weight: $font-weight-medium;
		margin-bottom: $spacing-xxs;
	}

	&-description {
		font-size: $font-size-lg;
	}

	&-progress {
		margin-top: 5px;
		display: flex;
		align-items: center;
		gap: $spacing-xxs;

		&-count {
			color: $color-main;
		}

		&-bar {
			width: 100%;
			height: 12px;
			background-color: $color-neutral-90;
			border-radius: 4px;

			&-progress {
				width: 0;
				height: 12px;
				background-color: $color-main;
				border-radius: 4px;
			}
		}
	}
}

.switch-container {
	margin-top: $spacing-s;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	border: 2px solid $color-main;
	border-radius: 8px;
	font-size: $font-size-md;

	.content {
		padding: $padding-sm $padding-xs;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $color-main;
		font-weight: $font-weight-bold;
		cursor: pointer;
		text-align: center;
	}

	.activated {
		background-color: $color-main;
		color: $color-neutral-light;
		font-weight: normal;
	}
}

.stats-radial-bar-container {
	box-shadow: 0px -4px 20px rgb(0 0 0 / 10%);
	border-radius: 10px;
	background-color: #fff;
	width: 100%;
	display: flex;
	border: 1px solid $color-main-90;

	&-information {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: $spacing-xs;

		&-title {
			font-size: $font-size-lg;
			font-weight: $font-weight-medium;
		}
	}

	.responsive-radial-bar {
		height: 150px;
		position: relative;
		width: 45%;
		margin-left: 5px;
	}

	.radialBar {
		&-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			align-content: center;
			position: absolute;
			text-align: center;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&-text {
			color: $color-neutral-80;
			font-size: $font-size-md;
			line-height: $line-height-md;
		}

		&-number {
			color: $color-main;
			font-size: 28px;
			font-weight: $font-weight-bold;
			line-height: 39px;
			max-width: 115px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.unlock-badge-notification {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;

	&-container {
		flex-grow: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&-content {
		display: flex;
		flex-direction: column;
		gap: 100px;
		justify-content: center;
		align-items: center;

		&-congratulations {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: $spacing-md;

			&-title {
				font-size: 26px;
				color: $color-main;
				font-weight: $font-weight-bold;
				text-align: center;
				max-width: 220px;
			}

			&-description {
				font-size: $font-size-mmlg;
				color: $color-neutral-60;
				text-align: center;
				max-width: 320px;

				span {
					font-weight: $font-weight-medium;
					cursor: pointer;
				}
			}
		}

		&-badge {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			padding: 0 $padding-sml;

			&-icon {
				width: 160px;
				height: 160px;
			}

			&-gif {
				position: absolute;
			}

			&-lottie-gif {
				top: 0;
				position: absolute;
			}

			&-title {
				font-size: 30px;
				color: $color-heading;
				font-weight: $font-weight-bold;
				text-align: center;
			}

			&-description {
				font-size: $font-size-mmlg;
				color: $color-neutral-80;
				text-align: center;
			}
		}
	}

	&-button {
		padding: 0 $padding-sml;
		margin-bottom: 50px;

		&-common {
			margin: 0;
			cursor: pointer;
		}
	}
}

.page-not-found {
	&-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex: 1;
		padding: 28px;
		gap: $spacing-m;
	}

	&-eye-icon {
		width: 120px;
		height: 64px;
	}

	&-title {
		font-size: $font-size-mmlg;
		font-weight: $font-weight-bold;
		text-align: center;
	}

	&-description {
		text-align: center;
	}
}

.last-updated {
	margin-top: $spacing-s;
	display: flex;
	justify-content: end;

	span {
		font-weight: $font-weight-bold;
	}
}
