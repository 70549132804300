.statistic-header {
	&-description {
		p {
			margin: $default-spacing;
		}

		display: flex;
		align-content: center;
		align-items: center;
		justify-content: space-between;
		font-size: $font-size-md;

		.percentage {
			font-size: $font-size-lg;
			font-weight: $font-weight-bold;
		}
	}

	&-bar {
		margin: 0 $default-spacing;
		height: 5px;
		background-color: $color-background-stats-header;
	}

	&-wrapper {
		display: flex;
		justify-content: space-between;
		margin: 0 $default-spacing;
		padding: $default-spacing;
		align-items: center;
		background-color: $background-color-main;
		border-radius: 10px;
		@include sm-box-shadow;

		.loading-statistics-wrapper {
			width: 100%;

			.ph-item,
			.ph-row,
			.ph-col-12 {
				padding: 0;
				margin: 0;
				border: none;
				width: 100%;
				height: 25px;
			}
		}
		.office-occupancy {
			margin-left: $default-spacing;

			&-header {
				font-weight: bold;
				margin-bottom: 10px;
			}

			.dynamic-stats {
				display: flex;
				align-items: center;

				.statistics-info-dynamics {
					min-width: 50px;
					margin: 0;
					margin-right: $default-spacing;

					.icon {
						width: 12px;
						margin-right: 5px;
					}
				}
			}
		}

		.action-wrapper {
			margin-left: auto;
		}
	}
}
