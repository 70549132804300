.your-checkins {
	&-section {
		margin: 25px 0;

		.data-holder {
			margin-top: 6px;
		}
	}

	&-header {
		margin-left: $spacing-m;
		margin-bottom: 5px;
	}

	&-list {
		display: flex;
		overflow: scroll;
	}
}
