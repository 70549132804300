@media all and (min-width: $breakpoint-xxlg) and (min-height: $breakpoint-height-xlg) {
	.toast-booking-wrapper {
		border-radius: 20px;
	}
}

.toast-booking-wrapper {
	z-index: 1 !important;
}

.toast-booking {
	&-wrapper-opened {
		background-color: rgba($color: #e7e7e7, $alpha: 0.5) !important;
	}
	&-wrapper {
		background-color: transparent;
		position: absolute !important; //override the react sheet library

		&-hidden {
			display: none;
		}

		.react-modal-sheet-container {
			//override the react sheet library
			border-top-left-radius: 25px !important;
			border-top-right-radius: 25px !important;
			height: auto !important;
		}
		.swipe-down-handler {
			width: 100%;
			display: flex;
			justify-content: center;
			padding-top: 10px;

			.grey-bar {
				width: 30px;
				background-color: $color-neutral-95;
				height: 5px;
				border-radius: 25px;
			}
		}

		.close-button {
			justify-content: flex-end;

			p {
				margin: 0;
			}
		}
	}

	&-content {
		background-color: $background-color-main;
		transition: 0.5s;
		z-index: 10;
		margin-bottom: 50px;

		.toast-options {
			display: flex;
			flex-flow: column;
			width: 100%;

			.toast-option {
				padding: $spacing-m;
				margin: 0 $spacing-m;
				margin-bottom: $spacing-s;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				border-radius: 12px;
				text-align: center;
				background-color: $color-neutral-95;
				border: 1.5px solid $color-neutral-90;

				p {
					margin: 0;
					font-size: $font-size-mmlg;
				}

				.icon {
					margin-right: $spacing-xs;
					width: 30px;
					height: 30px;

					svg {
						width: 30px;
						height: 30px;
					}
				}
			}
		}
	}

	&-open {
		background-color: $color-neutral-95;
		z-index: 10;

		.toast-booking-content {
			bottom: 0;

			.custom-icon {
				width: 50px;
				height: 50px;
			}
		}
	}
}
